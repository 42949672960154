import Vue from 'vue'
import App from './App.vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper.css'
import './utils/rem.js'
import VueRouter from 'vue-router'
import router from '@/router'

Vue.config.productionTip = false

Vue.use(VueAwesomeSwiper)
Vue.use(VueRouter)


new Vue({
  el: '#app',
  router,
  render: h => h(App),
}).$mount('#app')
