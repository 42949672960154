import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router =  new VueRouter({
    mode: "history",
    routes: [
        {
            path: '/sp/:marketId',
            component: () => import('@/views/index.vue')
        },
        {
            path: '/hp/:marketId',
            component: () => import('@/views/hpIndex.vue')
        },
        {
            path: '/yd/:marketId',
            component: () => import('@/views/ydIndex.vue')
        },
        {
            // 1604848371201314819
            path: '/tv/:marketId',
            component: () => import('@/views/tv.vue')
        },
        {
            path: '/404',
            component: () => import('@/views/404.vue'),
            name: '404',
        },
        {
            path: '*',
            redirect: '/404'
        }
    ]
})

export default router
